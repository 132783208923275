import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"display":"inline-block","color":"#f56c6c","margin-left":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.isShowModal,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isShowModal) = $event)),
    title: _ctx.groupInfos.groupName + ' - 分类/货物关系管理',
    footerButtons: _ctx.footerButtons,
    closeOnClickModal: "",
    width: "900px",
    top: "10vh",
    labelWidth: "80px"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Draggable, _mergeProps({
        modelValue: _ctx.groupData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupData) = $event))
      }, _ctx.dragOptions, {
        onEnd: _ctx.onDragEnd,
        "component-data": _ctx.dragComponents
      }), {
        item: _withCtx(({ element , index }) => [
          _createVNode(_component_el_row, {
            class: _normalizeClass(["relation-edit-row-box", {'hightlight': element.isChecked}])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                size: "small",
                label: (index + 1).toString(),
                style: {"margin-bottom":"0"},
                "label-width": "40px"
              }, null, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                size: "small",
                label: "分类",
                style: {"margin-bottom":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    style: {"width":"200px"},
                    modelValue: element.categoryId,
                    "onUpdate:modelValue": ($event: any) => ((element.categoryId) = $event),
                    onChange: _ctx.checkSameGroupData
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hwCategoryList, (subitem) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: subitem.label,
                          value: subitem.value
                        }, null, 8, ["label", "value"]))
                      }), 256))
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_form_item, {
                size: "small",
                label: "货物",
                style: {"margin-bottom":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    style: {"width":"200px"},
                    modelValue: element.goodsId,
                    "onUpdate:modelValue": ($event: any) => ((element.goodsId) = $event),
                    onChange: _ctx.checkSameGroupData
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hwGoodsList, (subitem) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: subitem.label,
                          value: subitem.value
                        }, null, 8, ["label", "value"]))
                      }), 256))
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_form_item, {
                size: "small",
                style: {"margin-bottom":"0"},
                "label-width": "20px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "default",
                    icon: "el-icon-delete",
                    onClick: ($event: any) => (_ctx.deleteOneGroupData(index))
                  }, null, 8, ["onClick"]),
                  (element.sameNo)
                    ? (_openBlock(), _createElementBlock("b", _hoisted_1, "已有相同的项"))
                    : (_openBlock(), _createBlock(_component_el_button, {
                        key: 1,
                        type: "default",
                        icon: "el-icon-sort",
                        style: {"cursor":"move"}
                      }))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["class"])
        ]),
        _: 1
      }, 16, ["modelValue", "onEnd", "component-data"]),
      (_ctx.newGroupObj.isAdding)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: _normalizeClass(["relation-edit-row-box", {'hightlight': _ctx.newGroupObj.isChecked}])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                size: "small",
                label: "+",
                style: {"margin-bottom":"0"},
                "label-width": "40px"
              }),
              _createVNode(_component_el_form_item, {
                size: "small",
                label: "分类",
                style: {"margin-bottom":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    style: {"width":"200px"},
                    modelValue: _ctx.newGroupObj.categoryId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newGroupObj.categoryId) = $event)),
                    onChange: _ctx.checkGoodsList
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hwCategoryList, (subitem) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: subitem.label,
                          value: subitem.value
                        }, null, 8, ["label", "value"]))
                      }), 256))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                size: "small",
                label: "货物",
                style: {"margin-bottom":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    style: {"width":"200px"},
                    placeholder: "请先选择分类",
                    modelValue: _ctx.newGroupObj.goodsId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newGroupObj.goodsId) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hwGoodsList, (subitem) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (!subitem.isDisabled)
                            ? (_openBlock(), _createBlock(_component_el_option, {
                                key: 0,
                                label: subitem.label,
                                value: subitem.value
                              }, null, 8, ["label", "value"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 256))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                size: "small",
                style: {"margin-bottom":"0"},
                "label-width": "20px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addOneGroupData(2)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("确定")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "default",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addOneGroupData(0)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("取消")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_row, { style: {"justify-content":"center","margin-top":"18px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            size: "small",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addOneGroupData(1)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("添加")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "title", "footerButtons"]))
}