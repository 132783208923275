
	import { getGoodsCategory, getGoodsList, getGoodsCategoryRelationByGroupId, updateGoodsCategoryGroup } from '@/api/goods'
	import { defineComponent, onMounted, reactive, ref, toRef, nextTick } from 'vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { Buttons } from '@/types/BasicComponent'
  import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
  import Draggable from 'vuedraggable'
  
	export default defineComponent({
		components: {
      BasicFormDialog,
      Draggable
		},
		setup(){
      const isShowModal = ref(false)
      
      const isAjaxing = ref(false)
      
      const groupData = ref<AnyArray>([]) // 必须 ref ，拖动时视图才能同步更新！！！
      
      const groupInfos = reactive<AnyObject>({
        groupId: 0,
        groupName: ''
      })
      
      const dragOptions = reactive<AnyObject>({
        animation: 500,
        disabled: false,
        sort: true,
        group: 'relation_items',
        ghostClass: 'ghost',
        handle: '.el-icon-sort',
        itemKey: 'id',
        tag: 'transition-group'
      })
      
      const dragComponents = reactive<AnyObject>({
        tag: 'div',
        type: 'transition-group' 
      })
      
      const hwCategoryList = reactive<AnyArray>([])
      
      const hwGoodsList = reactive<AnyArray>([])
      
      const newGroupObj = reactive<AnyObject>({
        isAdding:   false,
        isChecked:  false,
        categoryId: '',
        goodsId:    ''
      })
      
      const footerButtons = reactive<Buttons>([
      	{
      		label: '提交',
      		type: 'primary',
      		onClick: () => {
            
            if(groupData.value.length <= 0){
              return !ElMessage.success('暂无需要提交的数据！')
            }else if(isAjaxing.value){
              return !ElMessage.info('正在提交...')
            }else if(newGroupObj.isAdding){
              newGroupObj.isChecked = true
              setTimeout(($exo) => { $exo.isChecked = false }, 2000, newGroupObj)
              return !ElMessage.error('请先 “确定” 或 “取消” 添加数据！')
            }else{
              isAjaxing.value = true
            }

            let postData = { //未完善，只保存一条数据，其他的数据会丢失！！！！
              'groupId': groupInfos.groupId,
              'details': groupData.value.map((item) => { return {'categoryId': item.categoryId, 'goodsId': item.goodsId} })
            }
            
            updateGoodsCategoryGroup(postData).then(() => {
              console.log('成功。。。')
              ElMessage.success('已提交')
              isAjaxing.value = false
              isShowModal.value = false
            }).catch(() =>{
              console.log('失败！！！')
              isAjaxing.value = false
            })
      		}
      	}, 
      	{
      		label: '取消',
      		type: 'default',
      		onClick: () => (isShowModal.value = false)
      	}
      ])
      
      const getOtherData = async () => {
        let res2 = await getGoodsCategory({})
        let res3 = await getGoodsList({})

        if(res2.data && res2.data.data){
          res2.data.data.forEach((item: AnyObject) => {
            hwCategoryList.push({'value': item.categoryId, 'label': item.categoryName})
          })
          console.log("分类数据加载完成...")
        }
        
        if(res3.data && res3.data.data){
          res3.data.data.forEach((item: AnyObject) => {
            hwGoodsList.push({'value': item.goodsId, 'label': item.goodsName})
          })
          console.log("货物数据加载完成...")
        }
      }
      
      const getTableData = async () => {
        groupData.value.splice(0, groupData.value.length)
      	let respone = await getGoodsCategoryRelationByGroupId(groupInfos.groupId)
      	if(respone.data && respone.data.data){
          respone.data.data.forEach((item: AnyObject) => {
            groupData.value.push(item)
          })
          checkSameGroupData()
      	}
      }
      
      const deleteOneGroupData = (index: number) => {
        if(index >= 0 && index < groupData.value.length){
          groupData.value[index].isChecked = true
          ElMessageBox.confirm('删除这条数据？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            groupData.value.splice(index, 1)
            checkSameGroupData()
            ElMessage.success('已删除')
          }).catch(() => {
            groupData.value[index].isChecked = false
          })
        }
      }
      
      const addOneGroupData = (code: number) => {
        if(code===0){
          newGroupObj.isAdding    = false
          newGroupObj.isChecked   = false
          newGroupObj.categoryId  = ''
          newGroupObj.goodsId     = ''
        }else if(code===1){
          if(newGroupObj.isAdding){
            newGroupObj.isChecked = true
            setTimeout(($exo) => { $exo.isChecked = false }, 2000, newGroupObj)
            return !ElMessage.error('请先完成当前新添加的项，再添加下一项！')
          }else{
            newGroupObj.isAdding = true
          }
        }else{
          if(!newGroupObj.categoryId){
            return !ElMessage.error('请选择分类！')
          }else if(!newGroupObj.goodsId){
            return !ElMessage.error('请选择货物！')
          }else if(groupData.value.findIndex(objx => (objx.categoryId === newGroupObj.categoryId && objx.goodsId === newGroupObj.goodsId)) >= 0){
            return !ElMessage.error('已有相同的项！')
          }else{
            groupData.value.push({ 'categoryId': newGroupObj.categoryId, 'goodsId': newGroupObj.goodsId })
            newGroupObj.isAdding    = false
            newGroupObj.isChecked   = false
            newGroupObj.categoryId  = ''
            newGroupObj.goodsId     = ''
            ElMessage.success('已添加')
          }
        }
        return true
      }
      
      const showAddeModal = (infos: AnyObject) => {
        if(infos && infos.id){
          groupInfos.groupId    = infos.id
          groupInfos.groupName  = infos.name
        }else{
          groupInfos.groupId    = 0
          groupInfos.groupName  = ""
        }
        getTableData()
        isShowModal.value = true
        isAjaxing.value = false
        addOneGroupData(0)
      }
      
      const checkGoodsList = (arg0: number) => {
        let gids: number[] = [];
        groupData.value.forEach((item, index) => {
          if(item.categoryId===arg0){
            gids.push(item.goodsId)
          }
        })
        
        hwGoodsList.forEach((item, index) => {
          item.isDisabled = (gids.indexOf(item.value) >= 0)
        })
      }
      
      const checkSameGroupData = () => { //检查是否有相同项的数据
        let counts = groupData.value.length
        
        for(let ii = 0; ii < counts; ii++){
          groupData.value[ii].sameNo = 0
          groupData.value[ii].isChecked = false
        }
        
        groupData.value.forEach((item, index) => {
          if(!item.sameNo){
            for(let ii = index + 1; ii < counts; ii++){
              if(groupData.value[ii].categoryId === item.categoryId && groupData.value[ii].goodsId === item.goodsId){
                item.sameNo = (ii + 1)
                item.isChecked = true
                groupData.value[ii].sameNo = (index + 1)
                groupData.value[ii].isChecked = true
              }
            }
          }
        })
      }
      
      const moveItemUpDown = (index: number, type: number) => { //上移或下移项
        if(index < 0 || index >= groupData.value.length){
          return false
        }
        
        if(type===1){ //上移
          if(index===0){ 
            return !ElMessage.info('已经是第一项了！')
          }
          
          let tempx = groupData.value[index]
          groupData.value[index] = groupData.value[index - 1]
          groupData.value[index - 1] = tempx
        }else{ //下移
          if((index + 1)===groupData.value.length){
            return !ElMessage.info('已经是最后一项了！')
          }
          
          let tempx = groupData.value[index]
          groupData.value[index] = groupData.value[index + 1]
          groupData.value[index + 1] = tempx
        }
      }

      const onDragEnd = (arg0: AnyObject) => {
        /* let tempx = groupData.value[arg0.newIndex]
        groupData.value[arg0.newIndex] = groupData.value[arg0.oldIndex]
        groupData.value[arg0.oldIndex] = tempx */
      }
      
			onMounted(() => {
        getOtherData()
			})

			return {
        isShowModal,
        groupInfos,
				groupData,
        newGroupObj,
        hwCategoryList,
        hwGoodsList,
        showAddeModal,
        footerButtons,
        deleteOneGroupData,
        addOneGroupData,
        checkGoodsList,
        checkSameGroupData,
        moveItemUpDown,
        dragOptions,
        dragComponents,
        onDragEnd
			}
		}
	})
