
  import { defineComponent, reactive, ref, onMounted } from '@vue/runtime-core'
  import { addOrUpdateGoodsGroup } from '@/api/goods'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { Buttons } from '@/types/BasicComponent'
  import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'
  import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
  
  export default defineComponent({
  	components: {
  		BasicFormDialog
  	},
    emits: ['reloadPageData'],
  	setup(props, context){
      const isShowModal = ref(false)
      const isAjaxing = ref(false)
      const modalTitle = ref("")
      const formRules = reactive<FormRulesMap>({
      	/* name: [
      		{ required: true, message: '请输入分组名称' },
      	],
      	description: [
      		{ required: true, message: '请输入分组描述' },
      	] */
      })
      const footerButtons = reactive<Buttons>([
      	{
      		label: '提交',
      		type: 'primary',
      		onClick: () => {
            if(isAjaxing.value){
              return !ElMessage.info('正在提交...')
            }
            
            isAjaxing.value = true
            addOrUpdateGoodsGroup(formData).then(() => {
              console.log('成功。。。')
              context.emit('reloadPageData', 1)
              ElMessage.success('已提交')
              isAjaxing.value = false
              isShowModal.value = false
            }).catch(() =>{
              console.log('失败！！！')
              isAjaxing.value = false
            })
      		}
      	}, 
      	{
      		label: '取消',
      		type: 'default',
      		onClick: () => (isShowModal.value = false)
      	}
      ])
      const formData = reactive<AnyObject>({
      	name: 	      "",
      	description: 	"",
        createTime:  "",
      	modifyTime: ""
      })
      const showAddeModal = (infos: AnyObject) => {
        if(infos && infos.id){
          formData.id              = infos.id
          formData.name            = infos.name
          formData.description     = infos.description
          formData.createTime      = infos.createTime
          formData.modifyTime      = infos.modifyTime
          modalTitle.value         = "修改分组"
        }else{
          formData.id              = ""
          formData.name            = ""
          formData.description     = ""
          formData.createTime      = ""
          formData.modifyTime      = ""
          modalTitle.value         = "添加分组"
        }
        
        isShowModal.value = true
        isAjaxing.value = false
      }
      
      return {
        isShowModal,
        modalTitle,
        footerButtons,
        formRules,
        formData,
        showAddeModal
      }
    }
  })
