
	import { getGoodsGroupPage } from '@/api/goods'
	import { defineComponent, onMounted, reactive, ref, toRef, nextTick} from 'vue'
  import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
	import usePagination from '@/utils/composables/usePagination'
  import GroupAdde from '@/views/goods/GroupAdde.vue'
  import RelationEdit from '@/views/goods/RelationEdit.vue'
  
	export default defineComponent({
		components: {
			BasicTablePage,
      GroupAdde,
      RelationEdit
		},
		setup(){
      
      const GroupAddeBox = ref()
      
      const RelationEditBox = ref()
      
			const tablePagination = usePagination()
			
      const searchData = reactive<AnyObject>({})
      
      const tableData = reactive<AnyArray>([])
			
      const searchInputs = reactive<Inputs>([
        {
          label: '分组名称',
          type: 'input',
          placeholder: '请输入分组名称',
          model: toRef(searchData, 'name'),
          width: '200px',
          clearable: true
        }
      ])
      
      const searchButtons = reactive<Buttons>([
      	{
      	  label: '查询',
      	  icon: 'el-icon-search',
      	  onClick: () => {
            getTableData()
          }
      	},
        {
          label: '新增分组',
          icon: 'el-icon-plus',
          onClick: () => {
            GroupAddeBox.value.showAddeModal(0)
          }
        }
      ])
      
			const tableColumns = reactive<TableColumns>([
				{
					type: 'index',
					label: '序号',
					width: '80px',
					align: 'center'
				}, {
					prop: 'name',
					label: '名称',
					align: 'center'
				}, {
					prop: 'description',
					label: '描述',
					align: 'center'
				}, {
					prop: 'createTime',
					label: '创建时间',
					align: 'center'
				}, {
					prop: 'modifyTime',
					label: '修改时间',
          align: 'center'
				}, {
					type: 'button',
					label: '操作',
          align: 'center',
					buttons: [{
						label: "修改",
						onClick: scope => {
              GroupAddeBox.value.showAddeModal(scope.row)
            }
					}, {
						label: "管理",
						onClick: scope => {
              RelationEditBox.value.showAddeModal(scope.row)
            }
					}]
				}
			])
			
      const getTableData = async () => {
      	const respone = await getGoodsGroupPage(Object.assign({ 
      		pageNo: 	  tablePagination.currentPage, 
      		pageSize: 	tablePagination.pageSize
      	}, searchData))
        tableData.splice(0, tableData.length)
      	if(respone.data && respone.data.data){
          nextTick(() => {
            (respone.data.data.records || []).forEach((item: AnyObject) => {
              tableData.push(item)
            })
          })
      	}
        return
      }
      
			onMounted(() => {
				getTableData()
			})
			
			return {
        getTableData,
				tableColumns,
				tableData,
        GroupAddeBox,
        RelationEditBox,
				tablePagination,
        searchInputs,
        searchButtons
			}
		}
	})
